import * as React from "react";
import Link from "../../components/link";
import Grid from "@quandoo-dev/cookbook/lib/grid";
import { Body } from "@quandoo-dev/cookbook/lib/typography";
import Divider from "@quandoo-dev/cookbook/lib/divider";
import Img, { GatsbyImageProps } from "gatsby-image";
import { Box } from "@quandoo-dev/cookbook/lib/layout";
import styled from "styled-components";

interface Post {
  title: string;
  category: {
    name: string;
    slug: string;
  };
  locale: {
    slug: string;
  };
  previewImage: {
    description: string;
    asset: GatsbyImageProps;
  };
  slug: string;
  timeToRead: string;
}

interface PostInRowProps {
  post: Post;
  hideDivider?: Boolean;
  showImage?: Boolean;
  staticImage: any;
}

const ArticleH3 = styled.h3`
  color: #3c4656;
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
`;

const CategoryTime = styled.span`
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
`;

const Mobile = styled.div`
  @media (min-width: 1024px) {
    display: none;
  }
`;

const StyledBox = styled(Box)`
  width: 100%;
  height: 176px;
  @media (min-width: 375px) {
    height: 245px;
  }
  @media (min-width: 1024px) {
    width: 214px;
    height: 98px;
  }
  @media (min-width: 1260px) {
    width: 280px;
    height: 128px;
  }
`;


const PostInRow: React.FC<PostInRowProps> = ({
  post,
  hideDivider,
  showImage,
  staticImage,
}) => {
  const divider = hideDivider ? null : <Divider color="#D8D8D8" mt="lg" />;

  const image =
    showImage && staticImage === null ? (
      <StyledBox>
        <Img
          alt={post.previewImage.description}
          fluid={post.previewImage.asset.fluid}
          style={{
            borderRadius: "8px",
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </StyledBox>
    ) : (
      <StyledBox>
        <img
          style={{
            borderRadius: "8px",
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          src={staticImage}
        />
      </StyledBox>
    );

  const link =
    staticImage === null
      ? `/${post.category.slug}/${post.slug}`
      : `/${post.category.slug}/${post.slug}`;

  const categorieTime =
    staticImage === null
      ? post.category.name.toUpperCase()
      : post.category.name.toUpperCase();

  return (
    <Grid
      mb={{ _: "md", md: "md", lg: "xxxl" }}
      mt={{ _: "sm", md: "sm", lg: "sm" }}
      mx={{ _: "0", md: "0", lg: "0" }}
    >
      <Grid.Row>
        <Grid.Item>
          <Link to={link}>
            {showImage ? image : ""}
            <Body mb="xs" mt="md">
              <CategoryTime>
                {categorieTime} &sdot; {post.timeToRead.toUpperCase()}
              </CategoryTime>
            </Body>
          </Link>
          <Link to={link}>
            <ArticleH3>{post.title}</ArticleH3>
          </Link>
        </Grid.Item>
      </Grid.Row>
      <Mobile>{divider}</Mobile>
    </Grid>
  );
};

PostInRow.defaultProps = {
  hideDivider: false,
  showImage: true,
};

export default PostInRow;
