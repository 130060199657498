import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Breadcrumbs, { Crumb } from "../components/breadcrumbs";
import Divider from "@quandoo-dev/cookbook/lib/divider";
import Info from "../components/author/info";
import Grid from "@quandoo-dev/cookbook/lib/grid";
import { Helmet } from "react-helmet";
import PostInRow from "../components/cards/postInRow";
import { getOneTrustId } from '../utils/one-trust-ids';

interface BlogProps {
  data: any;
}

const BlogAuthor: React.FC<BlogProps> = props => {
  const {
    data: {
      contentfulAuthor: {
        firstName,
        lastName,
        profileImage,
        bio: { bio },
        email,
        instagramLink,
        linkedInLink,
        slug,
        locale,
        metaTitle,
        metaDescription
      },
      canonical,
      allContentfulPost: { nodes: posts },
    },
  } = props;
  const crumbs = [
    {
      // TODO: the string for "Home" should come from Contentful according to locale
      name: "Home",
      to: `/`,
      item: `${locale.domain}`,
    },
    {
      name: firstName,
      item: `${locale.domain}/author/${slug}`,
    } as Crumb,
  ];
  const postsInRows = posts.map((post, index) => (
    <Grid.Item columns={[12, 12, 12, 3]} key={index}>
      <PostInRow
        post={post}
        hideDivider={posts.length - 1 == index}
        staticImage={null}
      ></PostInRow>
    </Grid.Item>
  ));
  const ldJson = {
    "@context": "http://schema.org/",
    "@type": "Person",
    name: firstName,
    hasOccupation: {
      "@type": "Occupation",
      name: "Author",
    },
  };

  return (
    <Layout locale={locale.slug}>
      <Helmet
        htmlAttributes={{
          lang: locale.languageCode,
        }}
        title={metaTitle}
        titleTemplate={firstName + " | " + locale.name}
        link={[
          {
            rel: "canonical",
            key: locale.domain + "/author/" + slug,
            href: locale.domain + "/author/" + slug,
          },
        ]}
        meta={[
          { name: `description`, metaDescription},
          { name: `robots`, content: `index, follow` },
          { name: `og:locale`, content: locale.languageCode },
          { name: `og:type`, content: `object` },
          { name: `og:title`, content: firstName + " | " + locale.name },
          { name: `og:description`, metaDescription },
          { name: `og:site_name`, content: locale.name },
          { name: `og:url`, content: locale.domain + "/author/" + slug },
          { name: `og:image`, content: profileImage.fluid.src },
          { name: `twitter:card`, content: profileImage.fluid.src },
          { name: `twitter:title`, content: firstName + " | " + locale.name },
          { name: `twitter:site`, content: "@" + locale.twitterAccountName },
        ]}
      >
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
        {locale.slug !== 'au' && <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  type="text/javascript" charSet="UTF-8" data-domain-script={getOneTrustId(locale.slug)} ></script>}
        {locale.slug !== 'au' && <script type="text/javascript">function OptanonWrapper() { }</script>}
      </Helmet>
      <Breadcrumbs crumbs={crumbs} />
      <Info
        author={{
          firstName: firstName,
          lastName: lastName,
          profileImage: profileImage,
          bio: bio,
          email: email,
          instagramLink: instagramLink,
          linkedInLink: linkedInLink,
          locale: locale
        }}
      />
      <div>
        <Grid.Row mb="xxxl">{postsInRows}</Grid.Row>
      </div>
    </Layout>
  );
};

export const query = graphql`
  fragment postFields on ContentfulPost {
    slug
    title
    previewImage {
      description
      asset {
        fluid(maxWidth: 536) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }
    featureImage {
      description
      asset {
        fluid(maxWidth: 536) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }
    description {
      description
    }
    category {
      name
      slug
    }
    locale {
      domain
      slug
    }
    timeToRead
  }
  query($slug: String!, $localeSlug: String!) {
    contentfulAuthor(
      slug: { eq: $slug }
      locale: { slug: { eq: $localeSlug } }
    ) {
      firstName
      lastName
      profileImage {
        fluid(maxWidth: 476) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
      bio {
        bio
      }
      email
      instagramLink
      linkedInLink
      slug
      locale {
        name
        slug
        languageCode
        domain
      }
      metaTitle
      metaDescription
    }
    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      filter: {
        locale: { slug: { eq: $localeSlug } }
        author: { slug: { eq: $slug } }
      }
    ) {
      nodes {
        ...postFields
      }
    }
  }
`;

export default BlogAuthor;
