import * as React from "react";
import Grid from "@quandoo-dev/cookbook/lib/grid";
import { Box } from "@quandoo-dev/cookbook/lib/layout";
import Img, { GatsbyImageProps } from "gatsby-image";
import Divider from "@quandoo-dev/cookbook/lib/divider";
import styled from "styled-components";
import LinkedinIcon from "../../assets/logo-linkedin-small.inline.svg";
import InstagramIcon from "../../assets/logo-instagram.inline.svg";
import EmailIcon from "../../assets/email.inline.svg";
import useTranslation from "../../hooks/useTranslation";

const H1 = styled.h1`
  font-weight: 600;
  color: #2d384d;
  font-size: 32px;
  margin: 0;
  @media (max-width: 599px) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    float: left;
  }
  @media (min-width: 600px) {
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 8px;
  }
  @media (min-width: 1024px) {
    font-size: 36px;
  }
`;

const H2 = styled.h2`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`;

const Mobile = styled.div`
  @media (min-width: 600px) {
    display: none;
  }
`;

const Desktop = styled.div`
  @media (max-width: 599px) {
    display: none;
  }
`;

const Body = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

interface Author {
  firstName: string;
  lastName: string;
  profileImage: GatsbyImageProps;
  bio: string;
  email: string;
  linkedInLink: string;
  instagramLink: string;
  locale: any;
}

interface InfoProps {
  author: Author;
}

const Info: React.FC<InfoProps> = ({ author }) => {
  const Description = (
    <div>
      <Grid.Row mb="md" mt={["xl", "md", "md", "md"]}>
        <Grid.Item columns={[12, 12, 8, 8]}>
          <Body>{author.bio}</Body>
        </Grid.Item>
      </Grid.Row>
      <Grid.Row mb="md">
        <Grid.Item columns={2}>
          <Divider color="dark" />
          <Divider color="dark" />
        </Grid.Item>
      </Grid.Row>
      <Grid.Row>
        {author.linkedInLink != null ? (
          <Grid.Item columns={[1.5, 1.5, 0.5, 0.5]}>
            <a href={author.linkedInLink}>
              <LinkedinIcon />
            </a>
          </Grid.Item>
        ) : null}
        {author.instagramLink != null ? (
          <Grid.Item columns={[1.5, 1.5, 0.5, 0.5]}>
            <a href={author.instagramLink}>
              <InstagramIcon />
            </a>
          </Grid.Item>
        ) : null}
        {author.email != null ? (
          <Grid.Item columns={[1.5, 1.5, 0.5, 0.5]}>
            <a href={"mailto:" + author.email}>
              <EmailIcon />
            </a>
          </Grid.Item>
        ) : null}
      </Grid.Row>
    </div>
  );
  return (
    <div>
      <Grid.Row mt={["md", "md", "xxxl", "xxxl"]}>
        <Grid.Item columns={[4, 3, 2, 2]}>
          <Img
            fluid={author.profileImage.fluid}
            style={{
              borderRadius: "50%",
            }}
          />
        </Grid.Item>
        <Grid.Item columns={[8, 9, 10, 10]}>
          <H1>
            {author.firstName} {author.lastName}
          </H1>
          <Desktop>{Description}</Desktop>
        </Grid.Item>
      </Grid.Row>
      <Mobile>{Description}</Mobile>
      <Grid.Row mt="xxxl">
        <Grid.Item>
          <H2>
            {useTranslation(author.locale.slug, "author.all_articles").replace(
              /%author%/g,
              author.firstName
            )}
          </H2>

          <Divider color="#D8D8D8" />
        </Grid.Item>
      </Grid.Row>
    </div>
  );
};

export default Info;
